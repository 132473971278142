<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="handleModal"
            ><v-icon>fas fa-plus</v-icon></v-btn
          >
          <v-expansion-panel-header
            class="py-0 px-4 text-h5"
            style="cursor: default"
          >
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" md="2" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="familias"
                    hide-details
                    autofocus
                    outlined
                    dense
                    @change="set_empresa_sucursal_filtro"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="py-1">
                  Marca
                  <v-autocomplete
                    v-model="filtro.marca"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="
                      empresas.filter(
                        (empresa) => empresa.familia == filtro.empresa
                      )
                    "
                    hide-details
                    outlined
                    dense
                    @change=""
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="provincia"
                    item-value="id"
                    tabindex="1"
                    :items="
                      sucursales.filter(
                        (suc) => suc.familia_id == filtro.empresa
                      )
                    "
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Suscripcion
                  <v-text-field
                    v-model.trim="filtro.suscripcion"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    tabindex="1"
                    :items="estados"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="9" class="py-1" :md="typeof fecha == 'object' ? 6 : 3">
                  <FechaPickerRango
                    v-model="fecha"
                    :limpiar.sync="limpiar_fecha"
                  />
                </v-col>
                <v-col class="">
                  <BtnFiltro clase="mt-0" :loading="load" @clear="limpiar()" />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-data-table
        class="cebra elevation-2 mt-2"
        item-key="id"
        sort-by="id"
        :headers="headers"
        :items="recibos"
        :loading="load"
        sort-desc
        dense
      >
        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
        <template
          v-for="header in headers.filter((header) =>
            header.hasOwnProperty('formatter')
          )"
          v-slot:[`item.${header.value}`]="{ value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="wpp"
            title="Enviar WhatsApp"
            class="mr-2"
            small
            @click="enviar_wpp(item.telefono)"
          >
            fab fa-whatsapp
          </v-icon>
          <v-icon
            color="info"
            class="mr-2"
            title="Imprimir"
            small
            @click="imprimir(item)"
          >
            fas fa-print
          </v-icon>
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="abrir_recibo(item)"
          >
            fas fa-eye
          </v-icon>

          <BtnConfirmar
            v-if="permiso && item.anular == 1"
            clase="mr-2"
            icono="fas fa-money-bill-wave"
            color="success"
            title="Rendir"
            :texto="`¿Desea <strong>rendir</strong> el recibo Nº <strong>${item.id}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="rendir(item)"
          />
          <BtnConfirmar
            v-if="permiso && item.anular == 1"
            clase="mr-2"
            icono="fas fa-ban"
            color="error"
            title="Anular"
            :texto="`¿Desea <strong>anular</strong> el recibo Nº <strong>${item.id}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="anular(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>

    <!-- Modal recibo -->
    <v-dialog v-model="dialog" max-width="1000" scrollable>
      <v-card :disabled="load">
        <v-card-title>
          <div
            class="mt-4"
            v-text="
              nuevo ? 'Nuevo recibo en efectivo' : `Recibo Nº ${reciboId}`
            "
            style="font-size: 20px"
          ></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = !dialog">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-sm-4 pb-0">
          <v-form ref="form">
            <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
              <v-col
                cols="12"
                class="d-flex justify-end align-center pt-0 px-1"
              >
                <v-spacer></v-spacer>
                <v-expand-x-transition>
                  <v-switch
                    v-if="!nuevo && permiso"
                    v-model="editar"
                    label="Editar"
                    class="d-flex align-center pt-3 my-0"
                    hide-details
                    dense
                  ></v-switch>
                </v-expand-x-transition>
              </v-col>
              <v-card>
                <v-col cols="12">
                  <v-form ref="form2">
                    <v-row class="pt-4">
                      <v-col cols="6" sm="3" md="3" class="py-0">
                        <v-autocomplete
                          v-model="recibo.familia_id"
                          label="Empresa"
                          item-text="nombre"
                          item-value="id"
                          tabindex="1"
                          :rules="editar ? [rules.required, rules.notdef] : []"
                          :items="familias"
                          :readonly="!nuevo || !editar"
                          :filled="!nuevo || !editar"
                          validate-on-blur
                          outlined
                          dense
                          @change="set_empresa_sucursal"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6" sm="3" md="3" class="py-0">
                        <v-autocomplete
                          v-model="recibo.empresa_id"
                          label="Marca"
                          item-text="nombre"
                          item-value="id"
                          tabindex="1"
                          :rules="editar ? [rules.required, rules.notdef] : []"
                          :items="
                            empresas.filter(
                              (empresa) => empresa.familia == recibo.familia_id
                            )
                          "
                          :readonly="!nuevo || !editar"
                          :filled="!nuevo || !editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        v-if="recibo.empresa != null"
                        cols="6"
                        sm="3"
                        lg="2"
                        class="py-1"
                      >
                        {{
                          empresas.find((em) => em.id == recibo.empresa).nombre
                        }} </v-col
                      ><v-col cols="6" md="2" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.preimpreso"
                          label="Suscripción"
                          tabindex="1"
                          :rules="editar ? [rules.required] : []"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" md="2" class="py-0">
                        <v-autocomplete
                          v-model="recibo.sucursal_id"
                          label="Sucursal"
                          item-text="provincia"
                          item-value="id"
                          tabindex="1"
                          :rules="editar ? [rules.required, rules.notdef] : []"
                          :items="
                            sucursales.filter(
                              (suc) => suc.familia_id == recibo.familia_id
                            )
                          "
                          :readonly="!nuevo || !editar"
                          :filled="!nuevo || !editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.nombre"
                          label="Nombre"
                          tabindex="1"
                          :rules="editar ? [rules.required] : []"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.apellido"
                          label="Apellido"
                          tabindex="1"
                          :rules="editar ? [rules.required] : []"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="2" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.telefono"
                          v-mask="'###############'"
                          label="Teléfono"
                          tabindex="1"
                          type="number"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" md="3" class="py-0">
                        <v-autocomplete
                          v-model="recibo.tipo_doc_id"
                          label="Tipo documento"
                          item-text="nombre"
                          item-value="id"
                          tabindex="1"
                          :rules="editar ? [rules.required] : []"
                          :items="tipos_doc"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6" sm="3" md="3" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.documento"
                          label="Nº Documento"
                          tabindex="1"
                          :rules="editar ? [rules.required] : []"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.email"
                          label="Email"
                          tabindex="1"
                          :rules="
                            editar ? (recibo.email ? [rules.email] : []) : []
                          "
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6" sm="3" md="2" class="py-0">
                        <v-text-field
                          v-model.trim="recibo.importe"
                          label="Importe"
                          type="number"
                          prefix="$"
                          tabindex="1"
                          :rules="editar ? [rules.required] : []"
                          :readonly="!editar"
                          :filled="!editar"
                          validate-on-blur
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-card>
              <v-col cols="12" class="d-flex justify-end">
                <v-btn color="secondary" @click="dialog = !dialog">
                  Volver
                </v-btn>
                <BtnConfirmar v-if="editar" clase="ml-3" @action="guardar()" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      :nombre="pdf_nombre"
      :titulo="pdf_titulo"
    />
  </v-row>
</template>

<script>
import { format_money } from "../../util/utils";
import FechaPickerRango from "../../components/util/FechaPickerRango.vue";
import BtnFiltro from "../../components/util/BtnFiltro.vue";
import BtnConfirmar from "../../components/util/BtnConfirmar.vue";
import { mapState } from "vuex";
import { reciboEfDycarA4 } from "../../util/plantillas/pdfs";
import PDFViewer from "../../util/plantillas/PDFViewer.vue";

export default {
  components: {
    FechaPickerRango,
    BtnFiltro,
    BtnConfirmar,
    PDFViewer,
  },
  data() {
    return {
      dialog: false,
      nuevo: false,
      editar: false,
      verItemId: null,
      reciboId: null,
      permiso: false,
      permiso_edicion: false,
      panel: 0,
      load: false,
      limpiar_fecha: false,
      false: true,
      dialog_pdf: false,
      pdf: null,
      pdf_nombre: "",
      pdf_titulo: "",
      estados: [],
      recibos: [],
      recibo: {
        familia_nombre: null,
        sucursal: null,
        sucursal_id: null,
        ptovta: null,
        nombre: null,
        apellido: null,
        tipo_doc: null,
        tipo_doc_id: null,
        num_doc: null,
        telefono: null,
        email: null,
        sol_ad: null,
        importe: null,
        estado: 1,
        marca: null,
      },
      fecha: '',
      recibo_original: {}, // copia de recibo para volver a como estaba cuando cancela la edicion
      rules: {
        required: (value) => !!value || "Campo requerido",
        notdef: (value) => value !== -1 || "Campo requerido",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email no válido";
        },
      },
      headers: [
        { text: "ID", value: "id", align: "end" },
        { text: "Suscripción", value: "preimpreso" },
        { text: "Nombre", value: "nombre" },
        { text: "Nº Documento", value: "documento", align: "end" },
        {
          text: "Importe",
          value: "importe",
          align: "end",
          formatter: format_money,
        },
        { text: "Usuario", value: "usuario" },
        { text: "Fecha", value: "fecha" },
        { text: "Estado", value: "estado_nombre" },
        { text: "Marca", value: "empresa_nombre" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      filtro: {
        empresa: null,
        sucursal: null,
        estado: null,
        fecha: null,
        fechaDesde: '',
        fechaHasta: null,
        marca: null,
        suscripcion: null,
      },
    };
  },

  created() {
    this.get_data();
  },

  computed: {
    ...mapState(["empresas", "familias"]),
    ...mapState("genericos", [
      "sucursales",
      "rango_fecha_desde",
      "rango_fecha_hasta",
      "tipos_doc",
    ]),
    ...mapState("recibos", ["abrirDialog"]),

    abrirDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.dialog = value;
      },
    },
  },

  watch: {
    recibos(val) {
      this.recibos = val;
    },

    editar(val) {
      if (val && !this.nuevo) {
        this.$refs.form.resetValidation();
      } else {
        // vuelve al estado originial
        this.recibo = Object.assign({}, this.recibo_original);
      }
    },

    dialog(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        // Resetea las validaciones
        this.$refs.form2.resetValidation();
        this.recibo = {};
      }
      if (newValue === false) {
        this.editar = false;
      }
      if (newValue && this.nuevo) {
        this.recibo = {
          tipo_doc_id: 1,
        };
        if (this.familias.length == 1) {
          this.recibo.familia_id = this.familias[0].id;
          this.set_empresa_sucursal();
        }
      }
    },
  },

  methods: {
    enviar_wpp(telefono) {
      if (telefono) {
        window.open(`https://wa.me/549${telefono}`, "_blank");
      } else {
        this.$store.dispatch("show_snackbar", {
          text: "No se encontró un numero de teléfono para el cliente",
          color: "warning",
        });
      }
    },

    handleModal() {
      this.abrirDialog = !this.abrirDialog;
      this.nuevo = true;
      this.recibo_original = {};
      this.editar = true;
    },

    cerrarDialog() {
      this.abrirDialog = !this.abrirDialog;
      this.editar = false;
      this.$refs.form.resetValidation();
    },

    async get_data() {
      this.$store.state.loading = true;
      await this.$store.dispatch("genericos/get_sucursales");
      await this.$store.dispatch("genericos/get_tipos_doc");
      await this.$store
        .dispatch("recibos/get_estados")
        .then((res) => {
          this.estados = res.data;
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });
      this.$store.state.loading = false;

      // si solo tiene 1 empresa la selecciona por defecto
      if (this.familias.length == 1) {
        this.filtro.empresa = this.familias[0].id;
        this.set_empresa_sucursal_filtro();
      }

      // verifica si tiene permisos para anular
      await this.verificar_permiso();
    },

    set_empresa_sucursal_filtro() {
      let empresa = this.empresas.filter(
        (emp) => emp.familia == this.filtro.empresa
      );
      this.filtro.marca = empresa.length == 1 ? empresa[0].id : null;

      let sucursal = this.sucursales.filter(
        (suc) => suc.familia_id === this.filtro.empresa
      );
      this.filtro.sucursal = sucursal.length == 1 ? sucursal[0].id : null;
    },

    set_empresa_sucursal() {
      let empresa = this.empresas.filter(
        (emp) => emp.familia == this.recibo.familia_id
      );
      this.recibo.empresa_id = empresa.length == 1 ? empresa[0].id : null;

      let sucursal = this.sucursales.filter(
        (suc) => suc.familia_id === this.recibo.familia_id
      );
      this.recibo.sucursal_id = sucursal.length == 1 ? sucursal[0].id : null;
    },

    async verificar_permiso() {
      this.$store.state.loading = true;
      await this.$store
        .dispatch("recibos/verificar_permiso_anulacion")
        .then((res) => {
          if (res.data[0].anular == 1) {
            this.permiso = true;
          }
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });

      await this.$store
        .dispatch("recibos/verificar_permiso_edicion")
        .then((res) => {
          if (res.data[0].editar == 1) {
            this.permiso_edicion = true;
          }
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });
      this.$store.state.loading = false;
    },

    async imprimir(recibo) {
      this.$store.state.loading = true;
      await this.$store
        .dispatch("recibos/get_recibo_data_print", recibo)
        .then(async (res) => {
          const numero = res.data[0].numero;
          // genera el pdf para imprimir
          this.pdf = await reciboEfDycarA4(res.data[0]);
          this.pdf_nombre = `recibo-efectivo-${numero}`;
          this.pdf_titulo = `Recibo en efectivo Nº ${numero}`;
          this.dialog_pdf = true;
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });
      this.$store.state.loading = false;
    },

    async buscar() {
      if (!this.filtro.empresa) {
        return this.$store.dispatch("show_snackbar", {
          text: "Debe ingresar una empresa",
          color: "orange",
        });
      }
      this.recibos = [];
      this.load = true;

      this.filtro.fechaDesde = this.fecha

      if (typeof this.filtro.fechaDesde === "string") {
        this.filtro.fechaDesde = this.filtro.fechaDesde;
      } else if (
        typeof this.filtro.fechaDesde === "object" &&
        this.filtro.fechaDesde.fecha_desde
      ) {
        this.filtro.fechaDesde = this.filtro.fechaDesde.fecha_desde;
        this.filtro.fechaHasta = this.filtro.fechaDesde.fecha_hasta || ""
      } else {
        this.filtro.fechaDesde = "";
        this.filtro.fechaHasta = ""
      }
      await this.$store
        .dispatch("recibos/get_recibos", this.filtro)
        .then((res) => {
          this.recibos = res.data;
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });
      this.load = false;
    },

    abrir_recibo(recibo) {
      if (recibo) {
        this.nuevo = false;
        this.verificar_permiso();
        this.abrirDialog = !this.abrirDialog;
        this.reciboId = recibo.id;
        this.recibo = Object.assign({}, recibo);
        this.recibo_original = Object.assign({}, this.recibo);
      }
    },
    async guardar() {
      if (this.$refs.form.validate()) {
        this.$store.state.loading = true;

        if (this.nuevo) {
          await this.$store
            .dispatch("recibos/nuevo_recibo", this.recibo)
            .then(async (res) => {
              // guarda los datos en la copia del originial
              this.recibo_original = Object.assign({}, this.recibo);

              // mensaje de exito
              this.$store.dispatch("show_snackbar", {
                text: res.message,
                color: "success",
              });

              // obtiene el recibo que acaba de generar y lo muestra para imprimir

              await this.$store
                .dispatch("recibos/get_recibo_data_print", res)
                .then(async (recibo) => {
                  const data = recibo.data[0];
                  const numero = data.numero;
                  // genera el pdf para imprimir
                  this.pdf = await reciboEfDycarA4(data);
                  this.pdf_nombre = `recibo-efectivo-${numero}`;
                  this.pdf_titulo = `Recibo en efectivo Nº ${numero}`;
                  this.dialog_pdf = true;
                })
                .catch((error) => {
                  this.$store.dispatch("show_snackbar", {
                    text: error.message,
                    color: "error",
                  });
                });
            })
            .catch((error) => {
              this.$store.dispatch("show_snackbar", {
                text: error.message,
                color: "error",
              });
            });
        } else {
          await this.$store
            .dispatch("recibos/editar_recibo", this.recibo)
            .then((res) => {
              // guarda los datos nuevos en la copia del originial
              this.recibo_original = Object.assign({}, this.recibo);
              // actualiza los datos con el elemento editado.
              const elemento_actualizado = this.recibos.map((el) => {
                if (el.id === this.recibo.id) {
                  return Object.assign({}, el, this.recibo);
                }
                return el; // Devuelve el elemento original sin cambios
              });

              this.recibos = elemento_actualizado; // Actualiza 'this.recibos' con el nuevo array

              this.$store.dispatch("show_snackbar", {
                text: res.message,
                color: "success",
              });
            })
            .catch((error) => {
              this.$store.dispatch("show_snackbar", {
                text: error.message,
                color: "error",
              });
            });
        }

        this.editar = false;
        this.$store.state.loading = false;
      }
    },

    async rendir(recibo) {
      this.$store.state.loading = true;
      const reciboId = recibo.id;
      await this.$store
        .dispatch("recibos/rendir_recibo", reciboId)
        .then((res) => {
          // cambia el estado del item
          recibo.anular = 0;
          recibo.estado_nombre = this.estados.find((e) => e.value == 2).text;

          this.$store.dispatch("show_snackbar", {
            text: res.message,
            color: "success",
          });
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });
      this.$store.state.loading = false;
    },

    async anular(recibo) {
      this.$store.state.loading = true;

      await this.$store
        .dispatch("recibos/anular_recibo", recibo)
        .then((res) => {
          // cambia el estado del item
          recibo.anular = 0;
          recibo.estado_nombre = this.estados.find((e) => e.value == 3).text;

          this.$store.dispatch("show_snackbar", {
            text: res.message,
            color: "success",
          });
        })
        .catch((error) => {
          this.$store.dispatch("show_snackbar", {
            text: error.message,
            color: "error",
          });
        });
      this.$store.state.loading = false;
    },

    limpiar() {
      this.filtro = {
        empresa: null,
        sucursal: null,
        estado: null,
        fecha: null,
        fechaDesde: null,
        fechaHasta: null,
        marca: null,
      };
      this.limpiar_fecha = true;
    },
  },
};
</script>
